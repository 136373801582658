import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";

import hosts from "../assets/hosts.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiDeletePersona,
  apiUpdatePersona,
  apiUploadFile,
  apiUploadPersonaImage as apiGetPersonaImageUploadUrl,
  apiUploadPersonaAudio as apiGetPersonaAudioUploadUrl,
  useApiPersona,
  useApiVoices,
  apiGetPois,
  apiGetPoisBoundingBox,
  apiGenerateSingle,
  apiGenerateMulti,
  apiGenerateFollowUp,
} from "../api/api";
import { RefObject, useEffect, useRef, useState } from "react";
import LargeButton from "../components/input/LargeButton";
import {
  GoogleMap,
  InfoWindowF,
  LoadScript,
  MarkerF,
} from "@react-google-maps/api";
import { mapsApiKey, webSocketBaseUrl } from "../constants";
import Select, { MultiValue, StylesConfig } from "react-select";
import ImagePicker from "../components/input/ImagePicker";
import "./HostPage.css";
import dropdownIcon from "../assets/dropdown.png";
import Modal from "../components/layout/Modal";
import HostStageItem from "../components/input/HostStageItem";
import locationIcon from "../assets/location.png";
import { PlaceDetail } from "../model/poi";
import StoryResponse from "../model/story-response";
import AudioPicker from "../components/input/AudioPicker";
import { getUserToken } from "../firebase/firebase";
import MultiselectAccordionGooglePlacesTypes, { GooglePlacesTypeGroups } from "../components/input/MultiselectAccordionGooglePlacesTypes";

interface OptionType {
  value: string;
  label: string;
}
const defaultCenter = { lat: 40.7484, lng: 286.1157 };

interface MarkerType {
  id: number;
  position: {
    lat: number;
    lng: number;
  };
  name: string;
  poi: PlaceDetail;
}
const dropdownStylesMulti: StylesConfig<OptionType, true> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided) => ({
    ...provided,
    background: "#242424",
    borderColor: "#F0EDE033",
    borderRadius: 8,
    minHeight: "24px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#060606" : "#242424",
    color: state.isSelected ? "#ffffff" : "#cccccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff", // This sets the color of the selected item text to white
  }),
  menu: (provided) => ({
    ...provided,
    // Adjust these values as needed to change the overall menu background
    backgroundColor: "#242424", // Change this to match your desired background color
    padding: 0, // Set to 0 or adjust as needed to remove unwanted space
    borderRadius: "8px", // Optional: to match the control's borderRadius
  }),
};

const dropdownStyles: StylesConfig<OptionType, false> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided) => ({
    ...provided,
    background: "#242424",
    borderColor: "#F0EDE033",
    borderRadius: 8,
    minHeight: "24px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#060606" : "#242424",
    color: state.isSelected ? "#ffffff" : "#cccccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff", // This sets the color of the selected item text to white
  }),
  menu: (provided) => ({
    ...provided,
    // Adjust these values as needed to change the overall menu background
    backgroundColor: "#242424", // Change this to match your desired background color
    padding: 0, // Set to 0 or adjust as needed to remove unwanted space
    borderRadius: "8px", // Optional: to match the control's borderRadius
  }),
};
interface Location {
  lat: number;
  lng: number;
}
function HostPage() {
  interface AudioChunk {
    data: number[];
  }
  interface Payload {
    audioChunk?: AudioChunk;
    textChunk?: string;
    title?: string;
    audioUrl?: string;
    followUps?: FollowUp[];
  }

  interface FollowUp {
    id: number;
    content: string;
  }

  interface WebSocketData {
    type: "AUDIO" | "TRANSCRIPT" | "INFO";
    payload: Payload;
  }

  const { id } = useParams();
  const navigate = useNavigate();
  const host = useApiPersona(id!);
  const maxDescLength = 400;
  const voices = useApiVoices();
  const [streamFollowups, setStreamFollowups] = useState<FollowUp[] | null>(
    null
  );
  const [hostGenreTag1, setHostGenreTag1] = useState("");
  const [hostGenreTag2, setHostGenreTag2] = useState("");
  const [hostGenreTag3, setHostGenreTag3] = useState("");
  const [testStoryLoading, setTestStoryLoading] = useState(false);
  const [hostName, setHostName] = useState("");
  const [hostDesc, setHostDesc] = useState("");
  const [streamStoryTitle, setStreamStoryTitle] = useState("");
  const [streamTestTranscript, setStreamTestTranscript] = useState("");
  const [hostPrompt, setHostPrompt] = useState("");
  const [selectedPoiPrompt, setSelectedPoiPrompt] = useState("");
  const [surprisePrompt, setSurprisePrompt] = useState("");
  const [suggestionsPrompt, setSuggestionsPrompt] = useState("");
  const [followUpPrompt, setFollowUpPrompt] = useState("");
  const [hostStage, setHostStage] = useState("draft");
  const [pendingSave, setPendingSave] = useState(false);
  const [testStory, setTestStory] = useState<StoryResponse | null>(null);
  const [parentStory, setParentStory] = useState<StoryResponse | null>(null);
  const [placeDetails, setPlaceDetails] = useState<PlaceDetail[]>([]);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [lvl, setLvl] = useState(0);
  const [lastGenerationStartTime, setLastGenerationStartTime] = useState(
    Date.now()
  );
  const [generationTime, setGenerationTime] = useState(Date.now());
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
  const [selectedVoice, setSelectedVoice] = useState<OptionType | null>(null);
  const [selectedTextModel, setSelectedTextModel] = useState<OptionType | null>(
    null
  );
  let pendingRequest: (() => void) | null = null;
  const [selectedVoiceModel, setSelectedVoiceModel] =
    useState<OptionType | null>(null);
  const [selectedSubType, setSubType] = useState<OptionType | null>(null);
  const [selectedGenType, setGenType] = useState<OptionType | null>(null);
  const [hostVoiceStability, setHostVoiceStability] = useState(0);
  const [hostVoiceSimilarityBoost, setHostVoiceSimilarityBoost] = useState(0);
  const [hostVoiceStyle, setHostVoiceStyle] = useState(0);
  const [hostSpeakerBoost, setHostSpeakerBoost] = useState(false);
  const [streamDownloadUrl, setStreamDownloadUrl] = useState<string | null>("");
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [webSearchEnabled, setWebSearchEnabled] = useState(false);
  const [webSearchQueryTemplate, setWebSearchQueryTemplate] = useState("");
  const [selectedWebSearchTopic, setSelectedWebSearchTopic] =
    useState<OptionType | null>(null);
  const [webSearchIncludeDomains, setWebSearchIncludeDomains] = useState<
    string[]
  >([]);
  const [webSearchExcludeDomains, setWebSearchExcludeDomains] = useState<
    string[]
  >([]);
  const [webSearchIncludeDomainsInput, setWebSearchIncludeDomainsInput] =
    useState("");
  const [webSearchExcludeDomainsInput, setWebSearchExcludeDomainsInput] =
    useState("");
  const QueryTemplateRef = useRef<HTMLTextAreaElement>(null);
  const IncludeDomainsRef = useRef<HTMLTextAreaElement>(null);
  const ExcludeDomainsRef = useRef<HTMLTextAreaElement>(null);

  const mapRef = useRef<google.maps.Map>();
  const [currentBounds, setCurrentBounds] = useState<{
    northeast: google.maps.LatLngLiteral;
    southwest: google.maps.LatLngLiteral;
  } | null>(null);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

  const [markers, setMarkers] = useState<MarkerType[]>([]);
  // State to store the currently selected marker
  const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);
  const handleMarkerClick = (marker: MarkerType) => {
    setSelectedMarker(marker);
  };
  useEffect(() => {
    if (selectedGenType) {
      console.log("Selected Generation Type changed:", selectedGenType);
      setTestStory(null);
      setPlaceDetails([]);
    }
  }, [selectedGenType]);
  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };
  let audioBufferQueue: AudioBuffer[] = [];
  let audioBufferIndex = 0;
  let isBuffering = false;
  let audioContext = new (window.AudioContext ||
    (window as any).webkitAudioContext)();
  let playbackIndex = 0;
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error obtaining location:", error);
          // Handle error or set a default location
          setCurrentLocation({ lat: 40.712776, lng: -74.005974 }); // Default to New York City
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      // Set a default location
      setCurrentLocation({ lat: 40.712776, lng: -74.005974 }); // Default to New York City
    }
  }, []);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  function calculateDistance(
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) {
    const R = 6371e3; // Radius of the Earth in meters
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lng2 - lng1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in meters
    return `${Math.round(distance)}m`;
  }

  const [selectedPoiTypes, setSelectedPoiTypes] = useState<
    MultiValue<OptionType>
  >([]);

  const handlePoiTypesChange = (selected: MultiValue<OptionType>) => {
    setPendingSave(true);
    setSelectedPoiTypes(selected);
  };

  const handleHostSpeakerBoostChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostSpeakerBoost(event.target.checked);
    setPendingSave(true);
  };

  const handleHostVoiceStyleChange = (event: { target: { value: any } }) => {
    setHostVoiceStyle(Number(event.target.value));
    setPendingSave(true);
  };
  const handleHostSimilarityBoostChange = (event: {
    target: { value: any };
  }) => {
    setHostVoiceSimilarityBoost(Number(event.target.value));
    setPendingSave(true);
  };
  const handleHostVoiceStabilityChange = (event: {
    target: { value: any };
  }) => {
    setHostVoiceStability(Number(event.target.value));
    setPendingSave(true);
  };

  const [mapCenter, setMapCenter] = useState({
    lat: 40.797,
    lng: -73.644,
  });
  const handleValidImageSelected = (file: File) => {
    setSelectedImageFile(file);
    setPendingSave(true);
  };
  const handleValidAudioSelected = (file: File) => {
    setSelectedAudioFile(file);
    setPendingSave(true);
  };

  const handleWebSearchEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWebSearchEnabled(event.target.checked);
    setPendingSave(true);
  };
  const handleWebSearchQueryTemplateChange = (event: {
    target: { value: any };
  }) => {
    adjustTextareaHeight(QueryTemplateRef);
    setWebSearchQueryTemplate(event.target.value);
  };

  const handleWebSearchIncludeDomainsInputChange = (event: {
    target: { value: any };
  }) => {
    setWebSearchIncludeDomainsInput(event.target.value);
    adjustTextareaHeight(IncludeDomainsRef);
    const domainArray = event.target.value
      .split(" ")
      .map((domain: string) => domain.trim())
      .filter((domain: string) => domain !== "");
    setWebSearchIncludeDomains(domainArray);
  };
  const handleWebSearchExcludeDomainsInputChange = (event: {
    target: { value: any };
  }) => {
    setWebSearchExcludeDomainsInput(event.target.value);
    adjustTextareaHeight(ExcludeDomainsRef);
    const domainArray = event.target.value
      .split(" ")
      .map((domain: string) => domain.trim())
      .filter((domain: string) => domain !== "");
    setWebSearchExcludeDomains(domainArray);
  };

  const adjustTextareaHeight = (
    textareaRef: RefObject<HTMLTextAreaElement>
  ) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const savedIncludeDomains = host.data?.webSearchIncludeDomains ?? [];
    setWebSearchIncludeDomains(savedIncludeDomains);
    const savedExcludeDomains = host.data?.webSearchExcludeDomains ?? [];
    setWebSearchExcludeDomains(savedExcludeDomains);
    if (savedIncludeDomains.length > 0) {
      setWebSearchIncludeDomainsInput(savedIncludeDomains.join(" "));
    }
    if (savedExcludeDomains.length > 0) {
      setWebSearchExcludeDomainsInput(savedExcludeDomains.join(" "));
    }
  }, [host.data]);

  useEffect(() => {
    adjustTextareaHeight(IncludeDomainsRef);
    adjustTextareaHeight(ExcludeDomainsRef);
    adjustTextareaHeight(QueryTemplateRef);
  }, [
    webSearchIncludeDomainsInput,
    webSearchExcludeDomainsInput,
    webSearchQueryTemplate,
  ]);

  useEffect(() => {
    if (host.data != null && voices.data != null) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host.data, voices.data]);

  function getHostStage(key: string): string {
    switch (key) {
      case "prod":
        return "Production";
      case "staging":
        return "Staging";
      default:
        return "Draft";
    }
  }
  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMap(map);
  };
  const handleCenterChanged = () => {
    // Check if mapRef is current and accessible
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      setMapCenter({
        lat: newCenter?.lat() ?? mapCenter.lat,
        lng: newCenter?.lng() ?? mapCenter.lng,
      });
    }
  };

  function webSearchTopicOptions() {
    return [
      { label: "general", value: "general" },
      { label: "news", value: "news" },
    ];
  }
  function textModelOptions() {
    return [
      { label: "gpt-4-omni-mini", value: "gpt-4o-mini" },
      { label: "gpt-3.5-turbo", value: "gpt-3.5-turbo" },
      { label: "gpt-4-turbo", value: "gpt-4-turbo" },
      { label: "gpt-4-omni", value: "gpt-4o" },
      { label: "claude-3-haiku", value: "claude-3-haiku" },
      { label: "claude-3-sonnet", value: "claude-3-sonnet" },
      { label: "llama-3.1-405b", value: "llama-3.1-405b" },
      { label: "llama-3.1-70b", value: "llama-3.1-70b" },
      { label: "llama-3.1-8b", value: "llama-3.1-8b" },
      { label: "cohere-command-r", value: "cohere-command-r" },
      { label: "cohere-command-r-plus", value: "cohere-command-r-plus" },
    ];
  }
  function voiceModelOptions() {
    return [
      { label: "eleven_multilingual_v2", value: "eleven_multilingual_v2" },
      { label: "eleven_turbo_v2_5", value: "eleven_turbo_v2_5" },
      { label: "eleven_turbo_v2", value: "eleven_turbo_v2" },
      { label: "eleven_monolingual_v1", value: "eleven_monolingual_v1" },
      { label: "eleven_multilingual_v1", value: "eleven_multilingual_v1" },
    ];
  }

  function subTypeOptions() {
    return [
      { label: "Premium - Needs to be purchased separately", value: "premium" },
      { label: "Free - Unlimited use for everyone", value: "free" },
    ];
  }

  function generationTypeOptions() {
    return [
      {
        label: "Basic - Generate entire story",
        value: "basic",
      },
      {
        label: "Streaming - Listen while generating",
        value: "streaming",
      },
    ];
  }

  function voiceOptions() {
    var result: OptionType[] = [];
    if (voices.data) {
      voices.data.forEach((voice) => {
        result.push({ value: voice.voiceId.toString(), label: voice.name });
      });
    }
    return result;
  }

  function getGenreTagsArray(): string[] {
    var result = [];

    if (hostGenreTag1.length > 0) result.push(hostGenreTag1);
    if (hostGenreTag2.length > 0) result.push(hostGenreTag2);
    if (hostGenreTag3.length > 0) result.push(hostGenreTag3);
    return result;
  }

  function init() {
    setHostDesc(host.data?.description ?? "");
    setHostName(host.data?.name ?? "");
    setHostPrompt(host.data?.promptTemplateHost?.replace(/\\n/g, "\n") ?? "");

    setSurprisePrompt(
      host.data?.promptTemplatePoiMulti?.replace(/\\n/g, "\n") ?? ""
    );
    setSuggestionsPrompt(
      host.data?.promptTemplateFollowUpSuggestions?.replace(/\\n/g, "\n") ?? ""
    );

    setFollowUpPrompt(
      host.data?.promptTemplateFollowUp?.replace(/\\n/g, "\n") ?? ""
    );

    setSelectedPoiPrompt(
      host.data?.promptTemplatePoiSingle?.replace(/\\n/g, "\n") ?? ""
    );

    setHostGenreTag1(host.data?.genreTags[0] ?? "");
    setHostGenreTag2(host.data?.genreTags[1] ?? "");
    setHostGenreTag3(host.data?.genreTags[2] ?? "");
    setHostStage(host.data?.stage ?? "draft");

    if (host.data?.poiTypes) {
      const initialSelectedOptions = GooglePlacesTypeGroups.map(g => g.options).flat().filter((option) =>
        host.data?.poiTypes.includes(option.value)
      );
      setSelectedPoiTypes(initialSelectedOptions);
    }

    setHostVoiceSimilarityBoost(host.data?.voiceSimilarityBoost ?? 0);
    setHostVoiceStyle(host.data?.voiceStyle ?? 0);
    setHostVoiceStability(host.data?.voiceStability ?? 0);
    setHostSpeakerBoost(host.data?.useSpeakerBoost ?? false);

    setWebSearchEnabled(host.data?.webSearchEnabled ?? false);
    setWebSearchQueryTemplate(host.data?.webSearchQueryTemplate ?? "");
    setSelectedWebSearchTopic(
      webSearchTopicOptions().find(
        (it) => it.value === host.data?.webSearchTopic
      ) ?? webSearchTopicOptions()[0]
    );
    setWebSearchIncludeDomains(host.data?.webSearchIncludeDomains ?? []);
    setWebSearchExcludeDomains(host.data?.webSearchExcludeDomains ?? []);

    setSelectedTextModel(
      textModelOptions().find((it) => it.value === host.data?.textModel) ??
      textModelOptions()[0]
    );
    setSelectedVoiceModel(
      voiceModelOptions().find((it) => it.value === host.data?.voiceModel) ??
      voiceModelOptions()[0]
    );

    setSubType(
      subTypeOptions().find((it) => it.value === host.data?.subscriptionType) ??
      subTypeOptions()[0]
    );
    setGenType(generationTypeOptions()[0]);
    if (host.data?.voiceId && voices.data) {
      setSelectedVoice({
        value: host.data.voiceId,
        label:
          voices.data.find((it) => it.voiceId.toString() === host.data?.voiceId)
            ?.name ?? "NOT FOUND",
      });
    } else {
      console.log("missing voiceid");
    }
  }

  const formatTime = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, "0")}`; // Adds leading zero for single-digit seconds
  };

  function savePersona(pictureUrl?: string | null, audioUrl?: string | null) {
    if (selectedPoiTypes.length === 0) {
      alert(
        "Could not save without POI types. Please select at least one and try again."
      );
      return;
    }

    apiUpdatePersona({
      ...host.data!,
      stage: hostStage,
      subscriptionType: selectedSubType?.value ?? "free",
      name: hostName,
      description: hostDesc,
      promptTemplateHost: hostPrompt,
      promptTemplatePoiSingle: selectedPoiPrompt,
      promptTemplatePoiMulti: surprisePrompt,
      promptTemplateFollowUpSuggestions: suggestionsPrompt,
      promptTemplateFollowUp: followUpPrompt,
      voiceId: selectedVoice?.value,
      voiceSimilarityBoost: hostVoiceSimilarityBoost,
      voiceStyle: hostVoiceStyle,
      voiceStability: hostVoiceStability,
      poiTypes: selectedPoiTypes.map((it) => it.value),
      genreTags: getGenreTagsArray(),
      textModel: selectedTextModel?.value ?? textModelOptions()[0].value,
      voiceModel: selectedVoiceModel?.value ?? voiceModelOptions()[0].value,
      useSpeakerBoost: hostSpeakerBoost,
      pictureUrl: pictureUrl ?? host.data!.pictureUrl ?? "",
      audioSampleUrl: audioUrl ?? host.data!.audioSampleUrl ?? "",
      webSearchEnabled: webSearchEnabled,
      webSearchQueryTemplate: webSearchQueryTemplate,
      webSearchTopic:
        selectedWebSearchTopic?.value ?? webSearchTopicOptions()[0].value,
      webSearchIncludeDomains: webSearchIncludeDomains,
      webSearchExcludeDomains: webSearchExcludeDomains,
    }).then((response) => {
      if (response.error) alert("failed to save");
      setSelectedImageFile(null);
      setSelectedAudioFile(null);
      host.refresh();
      voices.refresh();
      setPendingSave(false);
    });
  }

  async function updateMapPOIs() {
    if (currentLocation == null || currentLocation === undefined) return;
    try {
      const places: PlaceDetail[] = await apiGetPoisBoundingBox(
        mapCenter.lat,
        mapCenter.lng,
        selectedPoiTypes.map((it) => it.value)
      );

      var newMarkers = [];
      for (let index = 0; index < places.length; index++) {
        const place = places[index];
        newMarkers.push({
          id: index,
          position: {
            lat: place.location.latitude,
            lng: place.location.longitude,
          },
          name: place.displayName.text,
          poi: place,
        });
      }
      setMarkers(newMarkers);
    } catch (error) { }
  }
  const handleBoundsChanged = () => {
    if (map) {
      const mapBounds = map.getBounds();
      if (mapBounds) {
        const northEast = mapBounds.getNorthEast(); // Get NE corner
        const southWest = mapBounds.getSouthWest(); // Get SW corner

        // Save bounds to state
        setCurrentBounds({
          northeast: northEast.toJSON(),
          southwest: southWest.toJSON(),
        });
      }
    }
  };

  async function generateTestStoryFromMulti() {
    setTestStoryLoading(true);
    setLastGenerationStartTime(Date.now());
    setStreamDownloadUrl(null);
    try {
      const places: PlaceDetail[] = await apiGetPois(
        mapCenter.lat,
        mapCenter.lng,
        5,
        selectedPoiTypes.map((it) => it.value)
      );
      setPlaceDetails(places);
      if (places.length === 0) {
        alert("Zero POIs found.");
        return;
      }

      if (selectedGenType!.value === "basic") {
        const testStory = await apiGenerateMulti({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          personaId: host.data!.id,
          test: true,
          pois: places,
        });
        setTestStory(testStory.data);
        setParentStory(testStory.data);
        setLvl(0);
        setGenerationTime(Date.now() - lastGenerationStartTime);
        setTestStoryLoading(false);
        return;
      } else if (selectedGenType!.value === "streaming") {
        var payload = {
          settings: {
            outputFormat: "pcm_44100",
          },
          location: {
            latitude: mapCenter.lat,
            longitude: mapCenter.lng,
          },
          personaId: host.data!.id,
          test: true,
          pois: places,
        };

        streamGenerateRabbitholePOIMulti(payload);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function streamGenerateRabbitholePOIMulti(data: any) {
    setStreamTestTranscript("");
    setStreamFollowups(null);

    pendingRequest = () => {
      socketRef.current!.send(
        JSON.stringify({ event: "generate-rabbithole-poi-multi", data })
      );
    };

    if (!(socket && socket.readyState === WebSocket.OPEN)) {
      initializeWebSocket(
        webSocketBaseUrl + "/rabbithole",
        await getUserToken(),
        pendingRequest
      );
    } else {
      pendingRequest();
    }
  }
  async function streamGenerateRabbitholeFollowUp(data: any) {
    setStreamTestTranscript("");
    setStreamFollowups(null);

    pendingRequest = () =>
      socketRef.current!.send(
        JSON.stringify({ event: "generate-rabbithole-followup", data })
      );

    if (!socket || socket.readyState !== WebSocket.OPEN) {
      initializeWebSocket(
        webSocketBaseUrl + "/rabbithole",
        await getUserToken(),
        pendingRequest
      );
    } else {
      pendingRequest();
    }
  }

  async function streamGenerateRabbitholePOISingle(data: any) {
    setStreamTestTranscript("");
    setStreamFollowups(null);

    pendingRequest = () =>
      socketRef.current!.send(
        JSON.stringify({ event: "generate-rabbithole-poi-single", data: data })
      );

    if (!socket || socket.readyState !== WebSocket.OPEN) {
      initializeWebSocket(
        webSocketBaseUrl + "/rabbithole",
        await getUserToken(),
        pendingRequest
      );
    } else {
      pendingRequest();
    }
  }

  const socketRef = useRef<WebSocket | null>(null);

  const initializeWebSocket = (
    url: string,
    token: string,
    callback?: () => void
  ) => {
    if (!token) {
      alert("Please enter a token");
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log("WebSocket already open");
      callback?.();
      return;
    }
    //@ts-ignore
    socketRef.current = new WebSocket(url, [token.replace("Bearer ", "")]);

    socketRef.current.onopen = () => {
      console.log("Connected to WebSocket server");
      callback?.();
    };

    socketRef.current.onclose = () => {
      console.log("Disconnected from WebSocket server");
      socketRef.current = null;
    };

    socketRef.current.onmessage = (event) => {
      console.log("Message", event.data);

      if (typeof event.data === "string") {
        const data: WebSocketData = JSON.parse(event.data);
        setTestStoryLoading(false);
        if (data.type === "AUDIO") {
          handleAudio(data.payload.audioChunk!, "audio/mpeg", "pcm_44100");
        } else if (data.type === "TRANSCRIPT") {
          console.log("got piece of transcript", data.payload.textChunk);
          setStreamTestTranscript(
            (prevTranscript) => prevTranscript + data.payload.textChunk
          );
        } else if (data.type === "INFO") {
          handleInfo(data.payload);
        }
      }
    };
  };
  function handleAudio(
    audioChunk: AudioChunk,
    audioFormat: string,
    outputFormat: string
  ) {
    const buffer = new Uint8Array(audioChunk.data);
    const blob = new Blob([buffer], { type: audioFormat });
    const reader = new FileReader();

    reader.onload = () => {
      const data: { audio: string } = JSON.parse(reader.result as string);
      if (!data.audio) {
        return;
      }

      const arrayBuffer = base64ToArrayBuffer(data.audio);
      if (!arrayBuffer || arrayBuffer.byteLength === 0) {
        return;
      }

      const currentIndex = audioBufferIndex++;

      processPCMData(arrayBuffer, currentIndex, outputFormat);
    };

    reader.readAsText(blob);
  }

  function processPCMData(
    arrayBuffer: ArrayBuffer,
    currentIndex: number,
    outputFormat: string
  ) {
    const sampleRate = getSampleRate(outputFormat);
    const pcmData = new Float32Array(arrayBuffer.byteLength / 2);
    const dataView = new DataView(arrayBuffer);

    for (let i = 0; i < pcmData.length; i++) {
      pcmData[i] = dataView.getInt16(i * 2, true) / 32768;
    }

    const audioBuffer = audioContext.createBuffer(
      1,
      pcmData.length,
      sampleRate
    );
    audioBuffer.copyToChannel(pcmData, 0);
    audioBufferQueue[currentIndex] = audioBuffer;

    if (!isBuffering) {
      playAudioBuffer();
    }
  }
  function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  function getSampleRate(outputFormat: string): number {
    const match = outputFormat.match(/pcm_(\d+)/);
    return match ? parseInt(match[1], 10) : 44100;
  }

  function playAudioBuffer() {
    console.log(
      "Playing audio buffer",
      !isBuffering,
      audioBufferQueue.length > playbackIndex,
      audioBufferQueue.length,
      playbackIndex
    );

    if (!isBuffering && audioBufferQueue.length > playbackIndex) {
      isBuffering = true;
      const buffer = audioBufferQueue[playbackIndex];
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start(0);
      source.onended = () => {
        playbackIndex++;
        isBuffering = false;
        playAudioBuffer();
      };
    } else {
      isBuffering = false;
    }
  }
  function handleInfo(payload: Payload) {
    if (payload.title) {
      console.log("got title", payload.title);
      setStreamStoryTitle(payload.title);
    }

    if (payload.followUps) {
      console.log("Got followups!", payload.followUps);
      setStreamFollowups(payload.followUps);
      setGenerationTime(Date.now() - lastGenerationStartTime);
    }

    if (payload.audioUrl) {
      console.log("got download url " + payload.audioUrl);
      setStreamDownloadUrl(payload.audioUrl);
    }
  }

  async function generateTestStoryFromSingle(poi: PlaceDetail) {
    setTestStoryLoading(true);
    setLastGenerationStartTime(Date.now());
    setStreamDownloadUrl(null);
    if (selectedGenType!.value === "basic") {
      try {
        const testStory = await apiGenerateSingle({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          personaId: host.data!.id,
          test: true,
          poi: poi,
        });
        setPlaceDetails([poi]);
        setTestStory(testStory.data);
        setParentStory(testStory.data);
        setLvl(0);
        setGenerationTime(Date.now() - lastGenerationStartTime);
        setTestStoryLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    } else if (selectedGenType!.value === "streaming") {
      var payload = {
        settings: {
          outputFormat: "pcm_44100",
        },
        location: {
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
        },
        personaId: host.data!.id,
        test: true,
        poi: poi,
      };
      streamGenerateRabbitholePOISingle(payload);
    }
  }

  async function generateTestStoryFromFollowUp(
    followupId: number,
    parentId: number
  ) {
    setTestStoryLoading(true);
    setLastGenerationStartTime(Date.now());
    setStreamDownloadUrl(null);
    if (selectedGenType!.value === "basic") {
      try {
        const testStory = await apiGenerateFollowUp(parentId, followupId);

        setTestStory(testStory.data);
        setLvl(lvl + 1);
      } catch (error) {
        console.log("error", error);
      }
      setGenerationTime(Date.now() - lastGenerationStartTime);
      setTestStoryLoading(false);
    } else if (selectedGenType!.value === "streaming") {
      var payload = {
        settings: {
          outputFormat: "pcm_44100",
        },
        followUpId: followupId,
        test: true,
      };
      streamGenerateRabbitholeFollowUp(payload);
    }
  }

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={hosts}
          alt="hosts"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          <span style={{ color: "#84FF81" }}>Hosts</span>
          <span>{" / " + hostName}</span>
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        >
          <div
            className="host-stage-selector"
            onClick={() => {
              setShowPublishDialog(true);
            }}
          >
            <p
              style={
                hostStage !== "prod"
                  ? { color: "#ED4F4F" }
                  : { color: "#84FF81" }
              }
            >
              • {getHostStage(hostStage)}
            </p>
            <div style={{ width: 20 }}></div>
            <img src={dropdownIcon} alt="icon" height={7} />
          </div>
          {pendingSave && (
            <LargeButton
              text="Save Changes"
              onClick={async () => {
                var imageUploadUrl = null;
                var audioUploadUrl = null;

                if (selectedImageFile != null) {
                  const imageUpload = await apiGetPersonaImageUploadUrl(
                    selectedImageFile.name
                  );
                  if (imageUpload.error) {
                    alert("Failed to get image upload url");
                    return;
                  }
                  const upload = await apiUploadFile(
                    imageUpload.data!.upload,
                    selectedImageFile
                  );
                  if (upload.error) {
                    alert("Failed to upload image");
                    return;
                  }
                  imageUploadUrl = imageUpload.data!.url;
                }

                if (selectedAudioFile != null) {
                  const audioUpload = await apiGetPersonaAudioUploadUrl(
                    selectedAudioFile.name
                  );
                  if (audioUpload.error) {
                    alert("Failed to get audio upload url");
                    return;
                  }
                  const upload = await apiUploadFile(
                    audioUpload.data!.upload,
                    selectedAudioFile
                  );
                  if (upload.error) {
                    alert("Failed to upload audio");
                    return;
                  }
                  audioUploadUrl = audioUpload.data!.url;
                }

                savePersona(imageUploadUrl, audioUploadUrl);
              }}
              icon={null}
              color={"#34A853"}
            />
          )}
          {pendingSave && (
            <div
              style={{
                borderRight: "1px solid #333333",
                boxSizing: "border-box",
                paddingLeft: "12px",
                marginRight: "12px",
                marginTop: 8,
                marginBottom: 8,
              }}
            ></div>
          )}
          <LargeButton
            text="Delete"
            outlineOnly={true}
            onClick={() => {
              const isConfirmed = window.confirm(
                "***WARNING***\n\nYou are about to delete a Persona.\n\nDeleting a Persona is irreversible and will remove all generated stories and data associated with this Persona.\n\nAre you sure you want to delete this Persona?"
              );
              if (isConfirmed) {
                setPendingSave(false);
                apiDeletePersona(host.data!.id)
                  .then(() => {
                    navigate("/hosts/");
                  })
                  .catch((error) => {
                    alert("Failed to delete persona:" + error);
                  });
              }
            }}
            icon={null}
            color={"red"}
          />
        </div>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={0} />
      <div className="content">
        <div className="content-split-left" style={{ paddingBottom: 40 }}>
          <h3>Host Name</h3>
          <small>
            This will be displayed in the app wherever the host is visible
          </small>
          <br />
          <input
            type="text"
            value={hostName}
            onChange={(value) => {
              setHostName(value.currentTarget.value);
              setPendingSave(true);
            }}
            style={{
              fontSize: 22,
              fontWeight: 600,
              outline: "none",
              marginTop: 8,
              boxSizing: "border-box",
              width: "100%",
              padding: 12,
            }}
          />
          <br /> <br />
          <h3>Host subscription type</h3>
          <small>Select who can use this host.</small>
          <br />
          <br />
          <Select
            value={selectedSubType}
            onChange={(newValue) => {
              setSubType(newValue);
              setPendingSave(true);
            }}
            options={subTypeOptions()}
            styles={dropdownStyles}
          />
          <div style={{ height: 18 }}></div>
          <h3>Host Description</h3>
          <small>
            This will be displayed in the app where users browse and select the
            host they want to listen to. Supports markdown. Limit to{" "}
            {maxDescLength.toString()} chars.
          </small>
          <textarea
            value={hostDesc}
            maxLength={maxDescLength}
            style={{ marginTop: 8 }}
            onChange={(value) => {
              setHostDesc(value.currentTarget.value);
              setPendingSave(true);
            }}
          ></textarea>
          <small
            style={
              hostDesc.length < maxDescLength
                ? { color: "#84FF81" }
                : { color: "orange" }
            }
          >
            Char count: {hostDesc.length.toString()}
          </small>
          <br />
          <br />
          <h3>Host Audio Sample</h3>
          <small>
            This is a sample of the host's stories. Users can listen too it when
            browsing hosts.
          </small>
          <br />
          <br />
          {selectedAudioFile == null && !host.data?.audioSampleUrl && (
            <p style={{ margin: "10px 0px", color: "gray" }}>No Audio Sample</p>
          )}
          {selectedAudioFile != null && (
            <audio controls src={URL.createObjectURL(selectedAudioFile)}>
              Your browser does not support the audio element.
            </audio>
          )}
          {selectedAudioFile == null && host.data?.audioSampleUrl && (
            <>
              <audio controls src={host.data!.audioSampleUrl}>
                Your browser does not support the audio element.
              </audio>
              <a
                className="download-button"
                href={host.data!.audioSampleUrl}
                download
                target="_blank"
              >
                <button>Download</button>
              </a>
            </>
          )}
          <br />
          <br />
          <AudioPicker
            onValidAudioSelected={handleValidAudioSelected}
          ></AudioPicker>
          <small>
            <b>Audio requirements</b>
          </small>
          <br />
          <small>Use mp3 format</small>
          <br />
          <br />
          <h3>Host Attributes & Topics</h3>
          <small>
            These will be displayed in the app when browsing hosts to give users
            a quick overview of what to expect from this host. Enter up to 3 to
            give the best overview of your host.
          </small>
          <br />
          <br />
          <input
            type="text"
            value={hostGenreTag1}
            style={{ marginRight: 8 }}
            onChange={(value) => {
              setHostGenreTag1(value.currentTarget.value);
              setPendingSave(true);
            }}
          />
          <input
            type="text"
            value={hostGenreTag2}
            style={{ marginRight: 8 }}
            onChange={(value) => {
              setHostGenreTag2(value.currentTarget.value);
              setPendingSave(true);
            }}
          />
          <input
            type="text"
            value={hostGenreTag3}
            onChange={(value) => {
              setHostGenreTag3(value.currentTarget.value);
              setPendingSave(true);
            }}
          />
          <div>
            <MultiselectAccordionGooglePlacesTypes
              onChange={handlePoiTypesChange}
              selectedValues={selectedPoiTypes}
            />
          </div>
        </div>
        <div className="content-split-right">
          <h3 style={{ marginBottom: 18 }}>Host Avatar</h3>
          <div
            style={{
              width: 200,
              height: 200,
              backgroundColor: "#242424",
              borderRadius: 32,
              overflow: "hidden",
              marginBottom: 16,
            }}
          >
            {selectedImageFile == null && !host.data?.pictureUrl && (
              <p style={{ textAlign: "center", marginTop: 91, color: "gray" }}>
                No image
              </p>
            )}
            {selectedImageFile != null && (
              <img
                src={URL.createObjectURL(selectedImageFile)}
                alt="Selected"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {selectedImageFile == null && host.data?.pictureUrl && (
              <img
                src={host.data!.pictureUrl}
                alt="Selected"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
          <ImagePicker
            onValidImageSelected={handleValidImageSelected}
          ></ImagePicker>
          <small>
            <b>Image requirements</b>
          </small>
          <br />
          <small>Use png format</small>
          <br />
          <small>Must be at least 200px x 200 px</small>
          <br /> <br />
          <h3>Host voice</h3>
          <small>
            This identifies the ElevenLabs voice model that will be used to
            create the audible voice of this host
          </small>
          <div style={{ height: 8 }}></div>
          {voices.data && (
            <Select
              value={selectedVoice}
              onChange={(newValue) => {
                setSelectedVoice(newValue);
                setPendingSave(true);
              }}
              options={voiceOptions()}
              styles={dropdownStyles}
            />
          )}
          <div style={{ height: 10 }}></div>
          <h3>Voice preview</h3>
          {voices.data &&
            selectedVoice &&
            voices.data!.find(
              (it) => selectedVoice!.value === it.voiceId.toString()
            ) === undefined && <p>Could not preview this voice.</p>}
          <div style={{ height: 8 }}></div>
          {selectedVoice &&
            voices.data &&
            voices.data!.find(
              (it) => selectedVoice!.value === it.voiceId.toString()
            ) && (
              <audio
                controls
                src={
                  voices.data!.find(
                    (it) => selectedVoice!.value === it.voiceId.toString()
                  )!.previewUrl
                }
              >
                Your browser does not support the audio element.
              </audio>
            )}
          <div style={{ height: 22 }}></div>
          <small>Use speaker boost:</small>
          <br />
          <input
            type="checkbox"
            checked={hostSpeakerBoost}
            onChange={handleHostSpeakerBoostChange}
          />
          <div style={{ height: 10 }}></div>
          <small>Voice stability</small>
          <div style={{ paddingTop: 12 }}>
            <input
              type="number"
              min="0"
              max="1"
              step={0.1}
              value={hostVoiceStability}
              onChange={handleHostVoiceStabilityChange}
              style={{ transform: "translateY(-4px)", marginRight: 8 }}
            />
            <input
              type="range"
              min="0"
              step={0.1}
              max="1"
              value={hostVoiceStability}
              onChange={handleHostVoiceStabilityChange}
              style={{ width: "70%" }}
            />
          </div>
          <small>Voice similarity boost</small>
          <div style={{ paddingTop: 12 }}>
            <input
              type="number"
              min="0"
              step={0.1}
              max="1"
              value={hostVoiceSimilarityBoost}
              onChange={handleHostSimilarityBoostChange}
              style={{ transform: "translateY(-4px)", marginRight: 8 }}
            />
            <input
              type="range"
              min="0"
              max="1"
              step={0.1}
              value={hostVoiceSimilarityBoost}
              onChange={handleHostSimilarityBoostChange}
              style={{ width: "70%" }}
            />
          </div>
          <small>Voice style</small>
          <div style={{ paddingTop: 12 }}>
            <input
              type="number"
              min="0"
              max="1"
              step={0.1}
              value={hostVoiceStyle}
              onChange={handleHostVoiceStyleChange}
              style={{ transform: "translateY(-4px)", marginRight: 8 }}
            />
            <input
              type="range"
              min="0"
              max="1"
              step={0.1}
              value={hostVoiceStyle}
              onChange={handleHostVoiceStyleChange}
              style={{ width: "70%" }}
            />
          </div>
          <h3>Voice Model</h3>
          <Select
            value={selectedVoiceModel}
            onChange={(newValue) => {
              setSelectedVoiceModel(newValue);
              setPendingSave(true);
            }}
            options={voiceModelOptions()}
            styles={dropdownStyles}
          />
          <div style={{ height: 12 }}></div>
          <h3>Text Model</h3>
          <Select
            value={selectedTextModel}
            onChange={(newValue) => {
              setSelectedTextModel(newValue);
              setPendingSave(true);
            }}
            options={textModelOptions()}
            styles={dropdownStyles}
          />
          <div style={{ height: 38 }}></div>
        </div>
        <hr style={{ clear: "both" }} />
        {/* POI search */}
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>POI search</h1>
          <small>Augment POIs with web search </small>
          <br />
          <input
            type="checkbox"
            checked={webSearchEnabled}
            onChange={handleWebSearchEnabledChange}
          />
          <h3>Query template</h3>
          <small>
            Enter a template to use for the search. Following template variables
            are available:{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}poi.name{"}"}
            </span>
            ,{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}poi.address{"}"}
            </span>
            ,{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}poi.city{"}"}
            </span>
            ,{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}poi.country{"}"}
            </span>{" "}
          </small>
          <textarea
            ref={QueryTemplateRef}
            style={{
              marginTop: 8,
              minHeight: "unset",
              overflow: "hidden",
            }}
            value={webSearchQueryTemplate}
            placeholder="Example: Crime reports at {poi.name} {poi.address}"
            onChange={(value) => {
              setPendingSave(true);
              handleWebSearchQueryTemplateChange(value);
            }}
          ></textarea>
          <h3>Topic</h3>
          <Select
            value={selectedWebSearchTopic}
            onChange={(newValue) => {
              setPendingSave(true);
              setSelectedWebSearchTopic(newValue);
            }}
            options={webSearchTopicOptions()}
            styles={dropdownStyles}
          />
          <h3>Include domains</h3>
          <small>
            Enter a list of domains (urls) to include in the search.
          </small>
          <textarea
            ref={IncludeDomainsRef}
            style={{
              marginTop: 8,
              minHeight: "unset",
              overflow: "hidden",
            }}
            value={webSearchIncludeDomainsInput}
            placeholder="example.com another.com"
            onChange={(value) => {
              setPendingSave(true);
              handleWebSearchIncludeDomainsInputChange(value);
            }}
          ></textarea>
          <h3>Exclude domains</h3>
          <small>
            Enter a list of domains (urls) to exclude in the search.
          </small>
          <textarea
            ref={ExcludeDomainsRef}
            style={{
              marginTop: 8,
              minHeight: "unset",
              overflow: "hidden",
            }}
            value={webSearchExcludeDomainsInput}
            placeholder="example.com another.com"
            onChange={(value) => {
              setPendingSave(true);
              handleWebSearchExcludeDomainsInputChange(value);
            }}
          ></textarea>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>HOST PROMPT</h1>
          <textarea
            style={{ minHeight: 500 }}
            value={hostPrompt}
            onChange={(value) => {
              setPendingSave(true);
              setHostPrompt(value.currentTarget.value);
            }}
          ></textarea>
        </div>
        <div className="content-split-right">
          <p>
            <b>
              Your prompt should include any direction on what your host is an
              expert in, what they should look for and focus on in their
              stories, and how they should present and construct their stories.
            </b>
            <br />
            <br />
            You can adjust the prompts to your liking. Be descriptive and clear.
            You can also motivate the AI to do your bidding.
            <br />
            <br />
            There are three variables in the system prompt:
            <span style={{ color: "#34A853" }}>
              {" {"}city{"}"}
            </span>
            ,{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}
              country{"}"}
            </span>{" "}
            and{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}pois{"}"}
            </span>
            .
            <br />
            <br />
            The{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}city{"}"}
            </span>{" "}
            and{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}country{"}"}
            </span>{" "}
            variables give the AI some context about the location.
            <br />
            <br />
            All variables are fetched from external data sources.
            <br />
            <br />
            Note! The variables need to be inside the curly braces{" "}
            <span style={{ color: "#34A853" }}>
              {"{"}
              {"}"}
            </span>{" "}
            and their names need to be exactly as shown in the example prompt.
            Even different capitalization will cause the variable to not be
            replaced.
          </p>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>SELECTED POI USER PROMPT</h1>
          <textarea
            style={{ minHeight: 500 }}
            value={selectedPoiPrompt}
            onChange={(value) => {
              setPendingSave(true);
              setSelectedPoiPrompt(value.currentTarget.value);
            }}
          ></textarea>
        </div>
        <div className="content-split-right">
          <p>
            This prompt is used, in addition of the host prompt, when the user
            selects a distinct POI on the map. This prompt guides the AI to
            generate a story specifically about that one specific place.
            <br />
            <br />
            Variables in the prompt:
            <span style={{ color: "#34A853" }}>
              {" {"}poi{"}"}
            </span>
          </p>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>"SURPRISE ME" USER PROMPT</h1>
          <textarea
            style={{ minHeight: 500 }}
            value={surprisePrompt}
            onChange={(value) => {
              setPendingSave(true);
              setSurprisePrompt(value.currentTarget.value);
            }}
          ></textarea>
        </div>
        <div className="content-split-right">
          <p>
            This prompt is used along with the host prompt when the user selects
            “Surprise me!” in the app. It guides the AI to find interesting
            stories about a number of close by POIs.
            <br />
            <br />
            Variables in the prompt:
            <span style={{ color: "#34A853" }}>
              {" {"}pois{"}"}
            </span>
          </p>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>FOLLOW UP SUGGESTIONS HOST PROMPT</h1>
          <textarea
            style={{ minHeight: 500 }}
            value={suggestionsPrompt}
            onChange={(value) => {
              setPendingSave(true);
              setSuggestionsPrompt(value.currentTarget.value);
            }}
          ></textarea>
        </div>
        <div className="content-split-right">
          <p>
            This prompt is used to extract N amount of topics from the generated
            story. The topics are then presented to the user as the options for
            follow up stories.
            <br />
            <br />
            Variables in the prompt:
            <span style={{ color: "#34A853" }}>
              {" {"}n{"}"}
            </span>
          </p>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left" style={{ paddingBottom: 25 }}>
          <h1>FOLLOW UP USER PROMPT</h1>
          <textarea
            style={{ minHeight: 500 }}
            value={followUpPrompt}
            onChange={(value) => {
              setPendingSave(true);
              setFollowUpPrompt(value.currentTarget.value);
            }}
          ></textarea>
        </div>
        <div className="content-split-right">
          <p>
            This prompt is used when the user selects a follow up topic after
            the initial story is generated. This prompt guides the AI to
            generate a story about a specific follow up topic that is extracted
            from the previous story in the rabbit hole.
            <br />
            <br />
            Variables in the prompt:
            <span style={{ color: "#34A853" }}>
              {" {"}follow_up_topic{"}"}
            </span>
          </p>
        </div>
        <hr style={{ clear: "both" }} />
        <div className="content-split-left">
          <h1>TEST STORY GENERATION</h1>

          <Select
            value={selectedGenType}
            onChange={(newValue) => {
              setGenType(newValue);
            }}
            options={generationTypeOptions()}
            styles={dropdownStyles}
          />
          <br></br>
          {testStoryLoading && (
            <p className="blink-opacity">Generating story..</p>
          )}
          {!testStoryLoading && (
            <LargeButton
              text={"SURPRISE ME"}
              icon={null}
              color="#34A853"
              onClick={function (): void {
                if (pendingSave) {
                  alert("Please save changes before generating a test story.");
                  return;
                }
                generateTestStoryFromMulti();
              }}
            ></LargeButton>
          )}
          <div style={{ width: 12, height: 12, display: "inline-block" }}></div>
          {!testStoryLoading && (
            <LargeButton
              text={"SELECT POI FROM MAP"}
              icon={null}
              color="#777777"
              onClick={function (): void {
                if (pendingSave) {
                  alert("Please save changes before generating a test story.");
                  return;
                }
                updateMapPOIs();
              }}
            ></LargeButton>
          )}

          <br />
          <br />
        </div>
        <div className="content-split-left">
          <div style={{ position: "relative", width: "100%", height: 600 }}>
            <LoadScript googleMapsApiKey={mapsApiKey}>
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: 600,
                  borderRadius: 8,
                  overflow: "hidden",
                }}
                onLoad={handleLoad}
                onCenterChanged={handleCenterChanged}
                onBoundsChanged={handleBoundsChanged}
                center={currentLocation || defaultCenter}
                zoom={10}
              >
                {selectedMarker && (
                  <InfoWindowF
                    key={"infowindow"}
                    position={selectedMarker.position}
                    onCloseClick={handleInfoWindowClose}
                  >
                    <div>
                      <h4 style={{ color: "black" }}>{selectedMarker.name}</h4>
                      <LargeButton
                        text={"START RABBIT HOLE"}
                        icon={null}
                        color="#34A853"
                        onClick={function (): void {
                          if (pendingSave) {
                            alert(
                              "Please save changes before generating a test story."
                            );
                            return;
                          }
                          if (!testStoryLoading)
                            generateTestStoryFromSingle(selectedMarker.poi);
                        }}
                      ></LargeButton>
                    </div>
                  </InfoWindowF>
                )}

                {markers.map((marker) => {
                  return (
                    <MarkerF
                      key={marker.id}
                      position={marker.position}
                      onClick={() => handleMarkerClick(marker)}
                    />
                  );
                })}
              </GoogleMap>
            </LoadScript>
            {selectedMarker == null && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "30px", // Adjust the size as necessary
                  height: "30px", // Adjust the size as necessary
                  backgroundImage: `url(${locationIcon})`, // Use imported image
                  backgroundSize: "cover", // Ensures the image covers the div completely
                  transform: "translate(-50%, -50%)",
                  pointerEvents: "none", // Allows click events to pass through to the map
                }}
              />
            )}
          </div>
          <br />
        </div>
        <br style={{ clear: "both" }} /> <br />
        {!testStoryLoading &&
          selectedGenType?.value === "streaming" &&
          streamTestTranscript.length > 0 && (
            <div
              className="content-split-left"
              style={{ backgroundColor: "#34A8531A", minHeight: "100vh" }}
            >
              <h2>{streamStoryTitle ?? "Streaming your story.."}</h2>
              <p>{streamTestTranscript}</p>
              {generationTime < 999999 && (
                <p>{"Generating took " + generationTime / 1000 + " seconds"}</p>
              )}

              {streamFollowups && streamFollowups.length === 3 ? (
                <>
                  <br />
                  <p>Continue rabbit hole with topic:</p>

                  <LargeButton
                    text={streamFollowups[0].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(streamFollowups[0].id, 0);
                    }}
                  ></LargeButton>
                  <div style={{ width: 12, display: "inline-block" }}></div>
                  <LargeButton
                    text={streamFollowups[1].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(streamFollowups[1].id, 0);
                    }}
                  ></LargeButton>
                  <div style={{ width: 12, display: "inline-block" }}></div>
                  <LargeButton
                    text={streamFollowups[2].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(streamFollowups[2].id, 0);
                    }}
                  ></LargeButton>
                  <br />
                  <br />
                </>
              ) : (
                <small>
                  Followups will appear here once the story has fully
                  generated..
                </small>
              )}
              <br />
              {streamDownloadUrl != null && (
                <LargeButton
                  text={"Download .mp3"}
                  icon={null}
                  color="#cccccc"
                  onClick={function (): void {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = streamDownloadUrl;
                    downloadLink.download = streamStoryTitle + ".mp3";
                    downloadLink.click();
                  }}
                ></LargeButton>
              )}
            </div>
          )}
        {!testStoryLoading &&
          selectedGenType?.value === "basic" &&
          placeDetails.length > 0 && (
            <div
              className="content-split-left"
              style={{ backgroundColor: "#34A8531A", minHeight: "100vh" }}
            >
              <h2>{"LVL " + lvl + " - " + testStory?.title}</h2>
              <p style={{ paddingTop: 12 }}>{testStory?.transcript}</p>
              <br />

              {testStory && selectedGenType?.value === "basic" && (
                <>
                  <audio controls src={testStory.audioUrl}>
                    Your browser does not support the audio element.
                  </audio>
                  <a
                    className="download-button"
                    href={testStory.audioUrl}
                    download
                    target="_blank"
                  >
                    <button>Download</button>
                  </a>
                </>
              )}
              {generationTime < 999999 && (
                <p>{"Generating took " + generationTime / 1000 + " seconds"}</p>
              )}
              <br />
              {parentStory && testStory && (
                <>
                  <br />
                  <p>Continue rabbit hole with topic:</p>

                  <LargeButton
                    text={testStory.followUps[0].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(
                        testStory.followUps[0].id,
                        parentStory!.id
                      );
                    }}
                  ></LargeButton>
                  <div style={{ width: 12, display: "inline-block" }}></div>
                  <LargeButton
                    text={testStory.followUps[1].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(
                        testStory.followUps[1].id,
                        parentStory!.id
                      );
                    }}
                  ></LargeButton>
                  <div style={{ width: 12, display: "inline-block" }}></div>
                  <LargeButton
                    text={testStory.followUps[2].content}
                    icon={null}
                    color="#34A853"
                    onClick={function (): void {
                      if (pendingSave) {
                        alert(
                          "Please save changes before generating a test story."
                        );
                        return;
                      }
                      generateTestStoryFromFollowUp(
                        testStory.followUps[2].id,
                        parentStory!.id
                      );
                    }}
                  ></LargeButton>
                  <br />
                  <br />
                </>
              )}
            </div>
          )}
        {!testStoryLoading && placeDetails.length > 0 && (
          <div
            className="content-split-right"
            style={{ backgroundColor: "#34A8531A", minHeight: "100vh" }}
          >
            <h1>Location info</h1>
            {placeDetails.map((place, index) => (
              <div key={index}>
                <b>{(index + 1).toString() + ". " + place.displayName.text}</b>
                <br />
                <small>{place.formattedAddress}</small>
                <br />
                <small>
                  {"Distance " +
                    calculateDistance(
                      mapCenter.lat,
                      mapCenter.lng,
                      place.location.latitude,
                      place.location.longitude
                    )}
                </small>
                <br />
                <small>{place.types.join(", ")}</small>
                <br />
                <br />
              </div>
            ))}
          </div>
        )}
        <hr style={{ clear: "both" }} />
      </div>
      <Modal
        isOpen={showPublishDialog}
        onClose={() => {
          setShowPublishDialog(false);
        }}
      >
        <HostStageItem
          text={"Draft"}
          selected={hostStage === "draft"}
          onClick={function (): void {
            if (hostStage !== "draft") {
              setHostStage("draft");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
        <HostStageItem
          text={"Staging"}
          selected={hostStage === "staging"}
          onClick={function (): void {
            if (hostStage !== "staging") {
              setHostStage("staging");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
        <HostStageItem
          text={"Production"}
          selected={hostStage === "prod"}
          onClick={function (): void {
            if (hostStage !== "prod") {
              setHostStage("prod");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
      </Modal>
    </div>
  );
}
export default HostPage;
