import { MultiValue } from 'react-select';
import MultiselectAccordion from './MultiselectAccordion';
import { Option } from './MultiselectAccordion';

interface MultiselectAccordionProps {
    selectedValues: MultiValue<Option>;
    onChange: (selectedValues: Option[]) => void;
}

const MultiselectAccordionGooglePlacesTypes: React.FC<MultiselectAccordionProps> = ({ selectedValues, onChange }) => {
    return (
        <MultiselectAccordion
            groups={GooglePlacesTypeGroups}
            selectedValues={selectedValues}
            onChange={onChange}
            title="POI Types"
            description='Select the maps categories to be used by this host. These will define what POIs will be made available in map browsing views, and used in the prompts.'
        />
    );
};

export default MultiselectAccordionGooglePlacesTypes;


export const GooglePlacesTypeGroups = [
    {
        "label": "Automotive & Transportation",
        "options": [
            { "value": "airport", "label": "Airport" },
            { "value": "bus_station", "label": "Bus Station" },
            { "value": "bus_stop", "label": "Bus Stop" },
            { "value": "car_dealer", "label": "Car Dealer" },
            { "value": "car_rental", "label": "Car Rental" },
            { "value": "car_repair", "label": "Car Repair" },
            { "value": "car_wash", "label": "Car Wash" },
            { "value": "electric_vehicle_charging_station", "label": "Electric Vehicle Charging Station" },
            { "value": "ferry_terminal", "label": "Ferry Terminal" },
            { "value": "gas_station", "label": "Gas Station" },
            { "value": "heliport", "label": "Heliport" },
            { "value": "light_rail_station", "label": "Light Rail Station" },
            { "value": "park_and_ride", "label": "Park and Ride" },
            { "value": "parking", "label": "Parking" },
            { "value": "rest_stop", "label": "Rest Stop" },
            { "value": "subway_station", "label": "Subway Station" },
            { "value": "taxi_stand", "label": "Taxi Stand" },
            { "value": "train_station", "label": "Train Station" },
            { "value": "transit_depot", "label": "Transit Depot" },
            { "value": "transit_station", "label": "Transit Station" },
            { "value": "truck_stop", "label": "Truck Stop" }
        ]
    },
    {
        "label": "Arts & Entertainment",
        "options": [
            { "value": "amusement_center", "label": "Amusement Center" },
            { "value": "amusement_park", "label": "Amusement Park" },
            { "value": "aquarium", "label": "Aquarium" },
            { "value": "art_gallery", "label": "Art Gallery" },
            { "value": "banquet_hall", "label": "Banquet Hall" },
            { "value": "bowling_alley", "label": "Bowling Alley" },
            { "value": "casino", "label": "Casino" },
            { "value": "community_center", "label": "Community Center" },
            { "value": "convention_center", "label": "Convention Center" },
            { "value": "cultural_center", "label": "Cultural Center" },
            { "value": "dog_park", "label": "Dog Park" },
            { "value": "event_venue", "label": "Event Venue" },
            { "value": "farm", "label": "Farm" },
            { "value": "hiking_area", "label": "Hiking Area" },
            { "value": "historical_landmark", "label": "Historical Landmark" },
            { "value": "marina", "label": "Marina" },
            { "value": "movie_rental", "label": "Movie Rental" },
            { "value": "movie_theater", "label": "Movie Theater" },
            { "value": "museum", "label": "Museum" },
            { "value": "national_park", "label": "National Park" },
            { "value": "night_club", "label": "Night Club" },
            { "value": "park", "label": "Park" },
            { "value": "performing_arts_theater", "label": "Performing Arts Theater" },
            { "value": "tourist_attraction", "label": "Tourist Attraction" },
            { "value": "visitor_center", "label": "Visitor Center" },
            { "value": "wedding_venue", "label": "Wedding Venue" },
            { "value": "zoo", "label": "Zoo" },
        ]
    },
    {
        "label": "Education & Learning",
        "options": [
            { "value": "preschool", "label": "Preschool" },
            { "value": "primary_school", "label": "Primary School" },
            { "value": "school", "label": "School" },
            { "value": "secondary_school", "label": "Secondary School" },
            { "value": "university", "label": "University" },
            { "value": "library", "label": "Library" },
            { "value": "school_district", "label": "School District" }
        ]
    },
    {
        "label": "Food & Dining",
        "options": [
            { "value": "bar", "label": "Bar" },
            { "value": "cafe", "label": "Cafe" },
            { "value": "coffee_shop", "label": "Coffee Shop" },
            { "value": "ice_cream_shop", "label": "Ice Cream Shop" },
            { "value": "bakery", "label": "Bakery" },
            { "value": "meal_delivery", "label": "Meal Delivery" },
            { "value": "meal_takeaway", "label": "Meal Takeaway" },
            { "value": "restaurant", "label": "Restaurant" },
            { "value": "american_restaurant", "label": "American Restaurant" },
            { "value": "barbecue_restaurant", "label": "Barbecue Restaurant" },
            { "value": "brazilian_restaurant", "label": "Brazilian Restaurant" },
            { "value": "breakfast_restaurant", "label": "Breakfast Restaurant" },
            { "value": "brunch_restaurant", "label": "Brunch Restaurant" },
            { "value": "chinese_restaurant", "label": "Chinese Restaurant" },
            { "value": "fast_food_restaurant", "label": "Fast Food Restaurant" },
            { "value": "french_restaurant", "label": "French Restaurant" },
            { "value": "greek_restaurant", "label": "Greek Restaurant" },
            { "value": "hamburger_restaurant", "label": "Hamburger Restaurant" },
            { "value": "indian_restaurant", "label": "Indian Restaurant" },
            { "value": "indonesian_restaurant", "label": "Indonesian Restaurant" },
            { "value": "italian_restaurant", "label": "Italian Restaurant" },
            { "value": "japanese_restaurant", "label": "Japanese Restaurant" },
            { "value": "korean_restaurant", "label": "Korean Restaurant" },
            { "value": "lebanese_restaurant", "label": "Lebanese Restaurant" },
            { "value": "mediterranean_restaurant", "label": "Mediterranean Restaurant" },
            { "value": "mexican_restaurant", "label": "Mexican Restaurant" },
            { "value": "middle_eastern_restaurant", "label": "Middle Eastern Restaurant" },
            { "value": "pizza_restaurant", "label": "Pizza Restaurant" },
            { "value": "ramen_restaurant", "label": "Ramen Restaurant" },
            { "value": "sandwich_shop", "label": "Sandwich Shop" },
            { "value": "seafood_restaurant", "label": "Seafood Restaurant" },
            { "value": "spanish_restaurant", "label": "Spanish Restaurant" },
            { "value": "steak_house", "label": "Steak House" },
            { "value": "sushi_restaurant", "label": "Sushi Restaurant" },
            { "value": "thai_restaurant", "label": "Thai Restaurant" },
            { "value": "turkish_restaurant", "label": "Turkish Restaurant" },
            { "value": "vegan_restaurant", "label": "Vegan Restaurant" },
            { "value": "vegetarian_restaurant", "label": "Vegetarian Restaurant" },
            { "value": "vietnamese_restaurant", "label": "Vietnamese Restaurant" }
        ]
    },
    {
        "label": "Government & Public Services",
        "options": [
            { "value": "administrative_area_level_1", "label": "Administrative Area Level 1" },
            { "value": "administrative_area_level_2", "label": "Administrative Area Level 2" },
            { "value": "country", "label": "Country" },
            { "value": "locality", "label": "Locality" },
            { "value": "postal_code", "label": "Postal Code" },
            { "value": "city_hall", "label": "City Hall" },
            { "value": "courthouse", "label": "Courthouse" },
            { "value": "embassy", "label": "Embassy" },
            { "value": "fire_station", "label": "Fire Station" },
            { "value": "local_government_office", "label": "Local Government Office" },
            { "value": "police", "label": "Police" },
            { "value": "post_office", "label": "Post Office" }
        ]
    },
    {
        "label": "Health & Wellness",
        "options": [
            { "value": "dental_clinic", "label": "Dental Clinic" },
            { "value": "dentist", "label": "Dentist" },
            { "value": "doctor", "label": "Doctor" },
            { "value": "drugstore", "label": "Drugstore" },
            { "value": "hospital", "label": "Hospital" },
            { "value": "medical_lab", "label": "Medical Lab" },
            { "value": "pharmacy", "label": "Pharmacy" },
            { "value": "physiotherapist", "label": "Physiotherapist" },
            { "value": "spa", "label": "Spa" },
        ]
    },
    {
        "label": "Hospitality & Lodging",
        "options": [
            { "value": "bed_and_breakfast", "label": "Bed and Breakfast" },
            { "value": "campground", "label": "Campground" },
            { "value": "camping_cabin", "label": "Camping Cabin" },
            { "value": "cottage", "label": "Cottage" },
            { "value": "extended_stay_hotel", "label": "Extended Stay Hotel" },
            { "value": "farmstay", "label": "Farmstay" },
            { "value": "guest_house", "label": "Guest House" },
            { "value": "hostel", "label": "Hostel" },
            { "value": "hotel", "label": "Hotel" },
            { "value": "lodging", "label": "Lodging" },
            { "value": "motel", "label": "Motel" },
            { "value": "private_guest_room", "label": "Private Guest Room" },
            { "value": "resort_hotel", "label": "Resort Hotel" },
            { "value": "rv_park", "label": "RV Park" }
        ]
    },
    {
        "label": "Religious & Spiritual Places",
        "options": [
            { "value": "church", "label": "Church" },
            { "value": "hindu_temple", "label": "Hindu Temple" },
            { "value": "mosque", "label": "Mosque" },
            { "value": "synagogue", "label": "Synagogue" }
        ]
    },
    {
        "label": "Personal & Professional Services",
        "options": [
            { "value": "accounting", "label": "Accounting" },
            { "value": "atm", "label": "ATM" },
            { "value": "bank", "label": "Bank" },
            { "value": "barber_shop", "label": "Barber Shop" },
            { "value": "beauty_salon", "label": "Beauty Salon" },
            { "value": "cemetery", "label": "Cemetery" },
            { "value": "child_care_agency", "label": "Child Care Agency" },
            { "value": "consultant", "label": "Consultant" },
            { "value": "courier_service", "label": "Courier Service" },
            { "value": "electrician", "label": "Electrician" },
            { "value": "florist", "label": "Florist" },
            { "value": "funeral_home", "label": "Funeral Home" },
            { "value": "hair_care", "label": "Hair Care" },
            { "value": "hair_salon", "label": "Hair Salon" },
            { "value": "insurance_agency", "label": "Insurance Agency" },
            { "value": "laundry", "label": "Laundry" },
            { "value": "lawyer", "label": "Lawyer" },
            { "value": "locksmith", "label": "Locksmith" },
            { "value": "moving_company", "label": "Moving Company" },
            { "value": "painter", "label": "Painter" },
            { "value": "plumber", "label": "Plumber" },
            { "value": "real_estate_agency", "label": "Real Estate Agency" },
            { "value": "roofing_contractor", "label": "Roofing Contractor" },
            { "value": "storage", "label": "Storage" },
            { "value": "telecommunications_service_provider", "label": "Telecommunications Service Provider" },
            { "value": "travel_agency", "label": "Travel Agency" },
            { "value": "veterinary_care", "label": "Veterinary Care" },
        ]
    },
    {
        "label": "Shopping & Retail",
        "options": [
            { "value": "auto_parts_store", "label": "Auto Parts Store" },
            { "value": "bicycle_store", "label": "Bicycle Store" },
            { "value": "book_store", "label": "Book Store" },
            { "value": "cell_phone_store", "label": "Cell Phone Store" },
            { "value": "clothing_store", "label": "Clothing Store" },
            { "value": "convenience_store", "label": "Convenience Store" },
            { "value": "department_store", "label": "Department Store" },
            { "value": "discount_store", "label": "Discount Store" },
            { "value": "electronics_store", "label": "Electronics Store" },
            { "value": "furniture_store", "label": "Furniture Store" },
            { "value": "gift_shop", "label": "Gift Shop" },
            { "value": "grocery_store", "label": "Grocery Store" },
            { "value": "hardware_store", "label": "Hardware Store" },
            { "value": "home_goods_store", "label": "Home Goods Store" },
            { "value": "home_improvement_store", "label": "Home Improvement Store" },
            { "value": "jewelry_store", "label": "Jewelry Store" },
            { "value": "liquor_store", "label": "Liquor Store" },
            { "value": "market", "label": "Market" },
            { "value": "pet_store", "label": "Pet Store" },
            { "value": "shoe_store", "label": "Shoe Store" },
            { "value": "shopping_mall", "label": "Shopping Mall" },
            { "value": "sporting_goods_store", "label": "Sporting Goods Store" },
            { "value": "store", "label": "Store" },
            { "value": "supermarket", "label": "Supermarket" },
            { "value": "wholesaler", "label": "Wholesaler" }
        ]
    },
    {
        "label": "Sports & Recreation",
        "options": [
            { "value": "athletic_field", "label": "Athletic Field" },
            { "value": "fitness_center", "label": "Fitness Center" },
            { "value": "golf_course", "label": "Golf Course" },
            { "value": "gym", "label": "Gym" },
            { "value": "playground", "label": "Playground" },
            { "value": "ski_resort", "label": "Ski Resort" },
            { "value": "sports_club", "label": "Sports Club" },
            { "value": "sports_complex", "label": "Sports Complex" },
            { "value": "stadium", "label": "Stadium" },
            { "value": "swimming_pool", "label": "Swimming Pool" },
        ]
    }
];
