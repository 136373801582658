import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import ResizableGrid from "../components/layout/ResizableGrid";
import addIcon from "../assets/add.png";
import hosts from "../assets/hosts.png";
import {
  apiCreatePersona,
  apiReorderPersonas,
  useApiPersonas,
} from "../api/api";
import LargeButton from "../components/input/LargeButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import eventBus from "../event-bus";
import { PersonaModel } from "../model/persona";
import SmallButton from "../components/input/SmallButton";
import "../components/layout/GridItem.css";

function HostsPage() {
  const personas = useApiPersonas();
  const navigate = useNavigate();
  const [pendingSave, setPendingSave] = useState(false);
  const [personasOrdered, setPersonasOrdered] = useState<
    PersonaModel[] | null
  >();

  const isUserForbidden = personas.error?.response?.status === 403 ? true : false;

  const savePersonasOrder = () => {
    if (personasOrdered != null) {
      setPendingSave(false);
      const orderMap = Object.fromEntries(convertArrayToMap(personasOrdered));
      console.log(JSON.stringify(orderMap));
      apiReorderPersonas(orderMap).then(() => {
        personas.refresh();
      });
    }
  };
  const convertArrayToMap = (items: PersonaModel[]): Map<string, number> => {
    const resultMap = new Map<string, number>();

    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      resultMap.set(index.toString(), element.id);
    }

    return resultMap;
  };
  useEffect(() => {
    if (personas.data != null) setPersonasOrdered(personas.data);
  }, [personas.data]);

  function reorderItems(
    items: PersonaModel[],
    id: number,
    direction: "up" | "down"
  ) {
    const index = items.findIndex((item) => item.id === id);
    if (index === -1) return items; // Item not found

    // Determine new index based on direction
    let newIndex = index + (direction === "down" ? 1 : -1);

    // Check for out-of-bound indexes
    if (newIndex < 0 || newIndex >= items.length) return items; // Do nothing if out of bounds

    // Swap elements
    [items[index], items[newIndex]] = [items[newIndex], items[index]];

    setPersonasOrdered([...items]);
  }
  useEffect(() => {
    const handleBack = (personaId: string) => {
      console.log("move back", personaId);
      reorderItems(personasOrdered!, Number(personaId), "up");
      setPendingSave(true);
    };
    const handleForward = (personaId: string) => {
      console.log("move forward", personaId);
      reorderItems(personasOrdered!, Number(personaId), "down");
      setPendingSave(true);
    };
    eventBus.on("MOVE_BACK", handleBack);
    eventBus.on("MOVE_FORWARD", handleForward);

    return () => {
      eventBus.off("MOVE_FORWARD", handleForward);
      eventBus.off("MOVE_BACK", handleBack);
    };
  }, [personasOrdered]);
  return (
    <div className="app">
      <HeaderBar>
        <img
          src={hosts}
          alt="hosts"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Hosts
        </h1>
        {!isUserForbidden && (
          <div
            style={{
              display: "inline-flex",
              right: 0,
              position: "absolute",
              boxSizing: "border-box",
              padding: 15,
            }}
          >
            <LargeButton
              text="Add new host"
              onClick={() => {
                apiCreatePersona({
                  ...personas.data![0], //TODO check if exists
                  name: "New persona",
                  description: "",
                  genreTags: [],
                  pictureUrl: "",
                  audioSampleUrl: "",
                  stage: "draft",
                }).then((response) => {
                  navigate("/hosts/" + response.data!.id.toString());
                });
              }}
              icon={addIcon}
              color={"#F0EDE0"}
            />
          </div>
        )}
      </HeaderBar>

      <NavBar selectedIndex={0} isUserForbidden = {isUserForbidden}/>

      <div className="content" style={{ paddingLeft: 16, paddingRight: 16 }}>
        {!isUserForbidden ? (
          <div>
            <p
              style={{
                marginLeft: 4,
                paddingBottom: 28,
                maxWidth: 600,
                fontSize: 18,
              }}
            >
              Select any host to update the prompt elements that define their
              personality and focus their attention on particular topics or
              interests.
            </p>
            {pendingSave && (
              <div style={{ paddingBottom: "20px" }}>
                <p style={{ marginLeft: 8 }}>Order of hosts was edited.</p>
                <SmallButton
                  icon={null}
                  text="Save"
                  isOutlineOnly={false}
                  onClick={savePersonasOrder}
                />
              </div>
            )}
            <ResizableGrid items={personasOrdered ?? []} />
          </div>
        ) : (
          <div
            className="grid-item"
            style={{
              height: "auto",
              margin: "auto",
            }}
          >
            <div className="host-image">
              <img
                src="https://d1m9alagf2gv12.cloudfront.net/persona/placeholder.jpg"
                alt="persona"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <h1
              title="Insufficient Permissions"
              style={{
                maxLines: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Insufficient Permissions
            </h1>
            <p>Please contact your administrator for access.</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default HostsPage;
