import React, { useState } from 'react';
import './MultiselectAccordion.css';
import { MultiValue } from 'react-select';
import dropdownIcon from "../../assets/dropdown.png";

export interface Option {
  label: string;
  value: string;
}

interface Group {
  label: string;
  options: Option[];
}

interface MultiselectAccordionProps {
  groups: Group[];
  selectedValues: MultiValue<Option>;
  onChange: (selectedValues: Option[]) => void;
  title?: string;
  description?: string;
}

const MultiselectAccordion: React.FC<MultiselectAccordionProps> = ({ groups, selectedValues, onChange, title, description }) => {
  const [openSections, setOpenSections] = useState<string[]>([]);

  const toggleSection = (value: string) => {
    setOpenSections(prev =>
      prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
    );
  };

  const handleSelection = (option: Option) => {
    onChange(
      selectedValues.some(selected => selected.value === option.value)
        ? selectedValues.filter(selected => selected.value !== option.value)
        : [...selectedValues, option]
    );
  };

  const handleSelectAll = (e: React.MouseEvent, group: Group) => {
    e.preventDefault();
    e.stopPropagation();
    const newSelectedValues = [
      ...selectedValues.filter(selected => !group.options.some(option => option.value === selected.value)),
      ...group.options
    ];
    onChange(newSelectedValues);
  };

  const handleClearAll = (e: React.MouseEvent, group: Group) => {
    e.preventDefault();
    e.stopPropagation();
    const newSelectedValues = selectedValues.filter(selected => !group.options.some(option => option.value === selected.value));
    onChange(newSelectedValues);
  };

  const getSelectedCount = (group: Group) => {
    return group.options.filter(option => selectedValues.some(selected => selected.value === option.value)).length;
  };

  const getTotalSelectedCount = () => {
    return selectedValues.length;
  };

  const getTotalOptionsCount = () => {
    return groups.reduce((total, group) => total + group.options.length, 0);
  };

  return (
    <div className="multiselect-accordion">
      {title && (
        <h3 className="accordion-title">
          <span>{title}</span>
          <small className='accordion-selected-info'>{getTotalSelectedCount()} selected of {getTotalOptionsCount()} available</small>
        </h3>
      )}
      {description && <small className="accordion-description">{description}</small>}
      {groups.map(group => (
        <div key={group.label} className={`accordion-section ${openSections.includes(group.label) ? 'open' : ''}`}>
          <div className="accordion-header" onClick={() => toggleSection(group.label)}>
            <div className='accordion-header-left'>
              <h4 className='accordion-group-title'>{group.label}</h4>
              <button onClick={(e) => handleSelectAll(e, group)}>Select All</button>
              <button onClick={(e) => handleClearAll(e, group)}>Clear All</button>
            </div>
            <div className='accordion-header-right'>
              <small className='accordion-selected-info'>{getSelectedCount(group)} of {group.options.length} selected</small>
              <img src={dropdownIcon} alt="icon" height={7} />
            </div>
          </div>
          <div className="accordion-content">
            {group.options.map(option => (
              <div
                key={option.value}
                className={`accordion-option ${selectedValues.some(selected => selected.value === option.value) ? 'selected' : ''}`}
                onClick={() => handleSelection(option)}
              >
                {option.label}
              </div>
            ))}
          </div>

        </div>
      ))}
    </div>
  );
};

export default MultiselectAccordion;
